import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'score', 'factor', 'errors' ]

  initialize () {
    this.updateSelectedFactor()
  }

  updateSelectedFactor () {
    this.factorTargets.forEach(el => {
      const ratingVal = this.getRatingVal(el)
      el.classList.toggle('selected', ratingVal === this.currentScore)
    })

  }

  selectRating (event) {
    if (this.hasErrorsTarget) {
      this.errorsTarget.classList.add('is-hidden')
    }
    this.currentScore = this.getRatingVal(event.target)
  }

  getRatingVal (el) {
    return parseInt(el.getAttribute('data-factor-ratings-value'))
  }

  get currentScore () {
    return parseInt(this.scoreTarget.value)
  }

  set currentScore (value) {
    this.scoreTarget.value = value
    this.updateSelectedFactor()
  }
}
