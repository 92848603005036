import { Controller } from 'stimulus'
import Mailcheck from 'mailcheck'

const DOMAINS = ['msn.com', 'bellsouth.net', 'comcast.net', 'icloud.com',
  'mac.com', 'googlemail.com', 'att.net', 'cox.net', 'gmail.com', 'ymail.com',
  'aim.com', 'rogers.com', 'verizon.net', 'google.com', 'sbcglobal.net',
  'aol.com', 'me.com', 'charter.net']
const SECOND_LEVEL_DOMAINS = ["yahoo", "hotmail", "mail", "live", "outlook",
  "gmail"]
const TOP_LEVEL_DOMAINS = ["com", "net", "org", "edu"]

export default class extends Controller {
  static targets = ['answer', 'address', 'domain', 'helpText']

  update (event) {
    Mailcheck.run({
      email: this.answerTarget.value,
      domains: DOMAINS,
      secondLevelDomains: SECOND_LEVEL_DOMAINS,
      topLevelDomains: TOP_LEVEL_DOMAINS,
      suggested: (suggestion) => {
        this.updateSuggestion(suggestion)
      },
      empty: () => {
        this.clearSuggestion()
      }
    });
  }

  updateSuggestion (suggestion) {
    this.showHelpText()
    this.addressTarget.textContent = suggestion.address
    this.domainTarget.textContent = '@' + suggestion.domain
    this.suggestion = suggestion.full
  }

  showHelpText () {
    this.helpTextTargets.forEach(el => {
      el.classList.remove('is-hidden')
    })
  }

  clearSuggestion () {
    this.hideHelpText()
    this.addressTarget.textContent = ''
    this.domainTarget.textContent = ''
  }

  hideHelpText () {
    this.helpTextTargets.forEach(el => {
      el.classList.add('is-hidden')
    })
  }

  selectSuggestion () {
    this.answerTarget.value = this.suggestion
    this.clearSuggestion()
  }
}
