import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'error', 'option', 'answer' ]

  connect () {
    this.updateButtons()
  }

  answerQuestion (event) {
    this.currentAnswer = this.getAnswerVal(event.target)
    this.errorTarget.classList.add('is-hidden')
  }

  getAnswerVal (el) {
    return el.getAttribute('data-response-single-questions-answer')
  }

  updateButtons () {
    this.optionTargets.forEach(el => {
      const val = this.getAnswerVal(el)
      el.classList.toggle('is-info', val === this.currentAnswer)
      el.classList.toggle('is-selected', val === this.currentAnswer)
    })
  }

  get currentAnswer () {
    return this.answerTarget.value
  }

  set currentAnswer (value) {
    this.answerTarget.value = value
    this.updateButtons()
  }
}
