import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['score', 'star', 'errors', 'factorContainer',
    'factorErrors', 'allFactorsError']

  initialize () {
    this.updateSelectedStars()
  }

  selectRating (event) {
    this.showFactors()

    if (this.hasErrorsTarget) {
      this.errorsTarget.classList.add('is-hidden')
    }

    this.currentScore = this.getRatingVal(event.target)
  }

  showFactors () {
    if (this.hasFactorContainerTarget &&
      this.factorContainerTarget.classList.contains('is-hidden')) {

      this.factorContainerTarget.classList.remove('is-hidden')
      // if the factor container was hidden, the user had not seen it yet so
      //  any errors should be hidden when the container is first displayed
      this.factorErrorsTargets.forEach(el => {
        el.classList.add('is-hidden')
      })

    }

  }

  selectNegativeFactor (event) {
    this.hideNegativeFactorError()
  }

  hideNegativeFactorError () {
    if (this.hasAllFactorsErrorTarget) {
      this.allFactorsErrorTarget.classList.add('is-hidden')
    }
  }

  updateSelectedStars () {
    this.starTargets.forEach(el => {
      // written this way b/c IE will not allow assigning empty string to classList
      const classList = el.classList
      while (classList.length > 0) {
        classList.remove(classList.item(0))
      }

      if (this.getRatingVal(el) <= this.currentScore) {
        el.classList.add(`selected-${this.currentScore}`)
      }
    })
  }

  getRatingVal (el) {
    return parseInt(el.getAttribute('data-star-ratings-value'))
  }

  get currentScore () {
    return parseInt(this.scoreTarget.value)
  }

  set currentScore (value) {
    this.scoreTarget.value = value
    this.updateSelectedStars()

    if (value >= 3) {
      this.hideNegativeFactorError()
    }
  }
}
