import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'page', 'previous', 'next', 'submit', 'viewed', 
    'currentPageField', 'progress' ]

  initialize () {
    this.maxPage = parseInt(this.data.get('pageCount'))
    this.showCurrentPage()
    this.updateButtonState()
    this.updateViewedFlags()
    this.updateProgressBar()
  }

  previous (event) {
    event.preventDefault()

    if (this.currentPage > 1) {
      this.currentPage--
    }
  }

  showCurrentPage () {
    this.pageTargets.forEach(el => {
      const elPageNum = parseInt(el.getAttribute('data-pages-page-num'))
      el.classList.toggle("page--current", elPageNum === this.currentPage)
    })
  }

  updateButtonState () {
    // Previous Button
    if (this.currentPage === 1) {
      this.previousTarget.setAttribute('disabled', true)
    } else {
      this.previousTarget.removeAttribute('disabled')
    }

    // Next Button and Submit Button
    if (this.currentPage === this.maxPage) {
      this.nextTarget.classList.add('is-hidden')
      this.submitTarget.classList.remove('is-hidden')
    } else {
      this.nextTarget.classList.remove('is-hidden')
      this.submitTarget.classList.add('is-hidden')
    }
  }

  updateViewedFlags () {
    this.viewedTargets.forEach(el => {
      const elPageNum = parseInt(el.getAttribute('data-pages-page-num'))
      if (elPageNum === this.currentPage) {
        el.value = true
      }
    })
  }

  updateProgressBar () {
    const percent = this.currentPage / this.maxPage * 100.0
    this.progressTarget.value = percent
  }

  get currentPage () {
    return parseInt(this.data.get('currentPage'))
  }

  set currentPage (value) {
    this.data.set('currentPage', value)
    this.currentPageFieldTarget.value = value
    this.showCurrentPage()
    this.updateButtonState()
    this.updateViewedFlags()
    this.updateProgressBar()
  }
}
