import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'container' ]

  show (event) {
    this.containerTarget.classList.add('is-active')
    document.documentElement.classList.add('is-clipped')
  }

  close (event) {
    event.preventDefault()

    this.containerTarget.classList.remove('is-active')
    document.documentElement.classList.remove('is-clipped')
  }
}
