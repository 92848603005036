import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'answer', 'error' ]

  connect () {
    this.errorCleared = false
  }

  updateAnswer (event) {
    if (!this.errorCleared) {
      this.errorTarget.classList.add('is-hidden')
      this.errorCleared = true
    }
  }
}
