import Flatpickr from 'stimulus-flatpickr'

import 'flatpickr/dist/flatpickr.min.css'

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  initialize () {
    this.config = {
      altInput: true,
      altFormat: 'M d, Y',
      dateFormat: 'Y-m-d',
      maxDate: 'today'
    }
  }
}
