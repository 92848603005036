import { Controller } from 'stimulus'
import Inputmask from "inputmask/dist/inputmask/inputmask.numeric.extensions"

export default class extends Controller {
  static targets = [ 'input' ]

  connect () {
    var selector = this.inputTarget //document.getElementsByClassName("visit-code")[0]

    var im = new Inputmask("999  999  999  999  999  999  99")
    im.mask(selector)
  }
}
